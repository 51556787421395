import { useEffect } from "react"

// Page tracking
const getNewsletterSelectValue = (val) => {
    if (val === '1444542') return 1
    if (val === '1444543') return 2
    return ''
}
const getNewsletterSelectLanguageValue = (val) => {
    if (val === '1451033') return 'Deutsch'
    if (val === '1451034') return 'English'
    return ''
}

const useGTM = ({ path, locale, pageType }) => {
	useEffect(() => {
		const _locale = locale ? locale.substr(0, 2) : ""
		if (window) {
			const _pageType = () => {
				if (pageType && pageType.length > 0) return pageType
				if (path === "/" || path === "/en/") return "homepage"
				if (/^(\/[a-z]{1,2})?\/team\//.test(path)) return "team"
				if (/^(\/[a-z]{1,2})?\/menschen\//.test(path)) return "team"
				if (/^(\/[a-z]{1,2})?\/races\//.test(path)) return "saison"
				if (/^(\/[a-z]{1,2})?\/saison\//.test(path)) return "saison"
				if (/^(\/[a-z]{1,2})?\/rennkalender\//.test(path)) return "saison"
				if (/^(\/[a-z]{1,2})?\/resultate\//.test(path)) return "saison"
				if (/^(\/[a-z]{1,2})?\/know-how\//.test(path)) return "know_how"
				if (/^(\/[a-z]{1,2})?\/insights\//.test(path)) return "know_how"
				if (/^(\/[a-z]{1,2})?\/coaching\//.test(path)) return "know_how"
				if (/^(\/[a-z]{1,2})?\/gym\//.test(path)) return "know_how"
				if (/^(\/[a-z]{1,2})?\/news\//.test(path)) return "content_news"
				if (/^(\/[a-z]{1,2})?\/presse\//.test(path)) return "presse"
				if (/^(\/[a-z]{1,2})?\/photos\//.test(path)) return "presse"
				if (/^(\/[a-z]{1,2})?\/erlebnisse\//.test(path)) return "erlebnisse"
				if (/^(\/[a-z]{1,2})?\/workshop-touren\//.test(path)) return "erlebnisse"
				if (/^(\/[a-z]{1,2})?\/vip-pakete\//.test(path)) return "erlebnisse"
				if (/^(\/[a-z]{1,2})?\/simulator\//.test(path)) return "erlebnisse"
				return "content_other"
			}
			window.dataLayer = window.dataLayer || []
			const payload = {
				loggedIn: "no",
				subSystem: "website",
				event: "pageView",
				language: _locale,
				pageType: _pageType(),
			}
			window.dataLayer.push(payload)
		}
	},[])
}

// Form tracking

const GTMForm = ({ locale, formId, type, formSuccess, values }) => {
	useEffect(() => {
		const _locale = locale ? locale.substr(0, 2) : ""
		if (window && type === "open" || window && formSuccess ) {
			const _pageType = () => {
				if (formId === 9 && type === "open") return "autograph_form"
				if (formId === 9 && type === "confirmation") return "autograph_confirmation"
				if (formId === 8 && type === "open") return "kontakt_form"
				if (formId === 8 && type === "confirmation") return "kontakt_confirmation"
				if (formId === 5 && type === "open") return "simulator_form"
				if (formId === 5 && type === "confirmation") return "simulator_confirmation"
				if (formId === 4 && type === "open") return "workshop_form"
				if (formId === 4 && type === "confirmation") return "workshop_confirmation"
				if (formId === 6 && type === "open") return "gym_form"
				if (formId === 6 && type === "confirmation") return "gym_confirmation"
				if (formId === 7 && type === "open") return "coaching_form"
				if (formId === 7 && type === "confirmation") return "coaching_confirmation"
				if (formId === 3 && type === "open") return "vip_form"
				if (formId === 3 && type === "confirmation") return "vip_confirmation"
				if (formId === 2 && type === "open") return "newsletter_form"
				if (formId === 2 && type === "confirmation") return "newsletter_confirmation"
			}
			const _event = () => {
				if (formId === 8 && type === "open")
					return {
						"event_name": "kontakt_form_personal_data",
						"kontakt_form_personal_data": {
							"event_category": "kontakt form",
							"event_action": "personal data",
						},
					}
				if (formId === 8 && type === "confirmation")
					return {
						"event_name": "kontakt_form_confirmation",
						"kontakt_form_confirmation": {
							"event_category": "kontakt form",
							"event_action": "confirmation",
						},
					}
				if (formId === 5 && type === "open")
					return {
						"event_name": "simulator_form_personal_data",
						"simulator_form_personal_data": {
							"event_category": "simulator form",
							"event_action": "personal data",
						},
					}
				if (formId === 5 && type === "confirmation")
					return {
						"event_name": "simulator_form_confirmation",
						"simulator_form_confirmation": {
							"event_category": "simulator form",
							"event_action": "confirmation",
						},
					}
				if (formId === 4 && type === "open")
					return {
						"event_name": "workshop_form_personal_data",
						"workshop_form_personal_data": {
							"event_category": "workshop form",
							"event_action": "personal data",
						},
					}
				if (formId === 4 && type === "confirmation")
					return {
						"event_name": "workshop_form_confirmation",
						"workshop_form_confirmation": {
							"event_category": "workshop form",
							"event_action": "confirmation",
						},
					}
				if (formId === 6 && type === "open")
					return {
						"event_name": "gym_form_personal_data",
						"gym_form_personal_data": {
							"event_category": "gym form",
							"event_action": "personal data",
						},
					}
				if (formId === 6 && type === "confirmation")
					return {
						"event_name": "gym_form_confirmation",
						"gym_form_confirmation": {
							"event_category": "gym form",
							"event_action": "confirmation",
						},
					}
				if (formId === 7 && type === "open")
					return {
						"event_name": "coaching_form_personal_data",
						"coaching_form_personal_data": {
							"event_category": "coaching form",
							"event_action": "personal data",
						},
					}
				if (formId === 7 && type === "confirmation")
					return {
						"event_name": "coaching_form_confirmation",
						"coaching_form_confirmation": {
							"event_category": "coaching form",
							"event_action": "confirmation",
						},
					}
				if (formId === 3 && type === "open")
					return {
						"event_name": "vip_form_personal_data",
						"vip_form_personal_data": {
							"event_category": "vip form",
							"event_action": "personal data",
						},
					}
				if (formId === 3 && type === "confirmation" && !!values?.input_2)
					return {
						"event_name": "vip_form_confirmation",
						"vip_form_confirmation": {
							"circuit": `${values.input_2}`,
     						"ef_ma": `${values.input_22_1 === true ? "yes" : "no"}`, 
							"event_category": "vip form",
							"event_action": "confirmation",
						},
					}
				if (formId === 2 && type === "open")
					return {
						"event_name": "newsletter_form_personal_data",
						"newsletter_form_personal_data": {
							"event_category": "newsletter form",
							"event_action": "personal data",
						},
					}
				if (formId === 2 && type === "confirmation" && !!values?.newsletterSelectValue && !!values?.newsletterSelectLanguageValue)
					return {
						"event_name": "newsletter_form_confirmation",
						"newsletter_form_confirmation": {
							"abotype": getNewsletterSelectValue(values.newsletterSelectValue),
     						"nl_language": `${getNewsletterSelectLanguageValue(values.newsletterSelectLanguageValue)}`,
							"event_category": "newsletter form",
							"event_action": "confirmation",
						},
					}
					if (formId === 9 && type === "open")
						return {
							"event_name": "autograph_form_personal_data",
							"autograph_form_personal_data": {
								"event_category": "autograph form",
								"event_action": "personal data",
							},
						}
					if (formId === 9 && type === "confirmation")
						return {
							"event_name": "autograph_form_confirmation",
							"autograph_form_confirmation": {
								"event_category": "autograph form",
								"event_action": "confirmation",
							},
						}
				}
			
			window.dataLayer = window.dataLayer || []
			const payload = {
				"event": "gaEvent2",
                ..._event(),
                "pageType": _pageType()
            }
			window.dataLayer.push(payload)
		}
	}, [formSuccess])
}

export { useGTM, GTMForm }
