import React from 'react'
import { graphql, Link } from 'gatsby'
import tw, { styled } from 'twin.macro'
import WpAcfHeaderprimaryBlock from '@modules/WpAcfHeaderprimaryBlock'
import parse from 'html-react-parser'
import { strings } from '../static/strings'
import Chevron from '@elements/Chevron'
import Footer from '@root/Footer'
import { useGTM } from '@hooks/useGTM'
import listArrow from '@images/icons/arrow-right.svg'
import SEO from '../components/root/Seo'
import WpAcfImageBlock from '../components/modules/WpAcfImageBlock'
import NavBar from '@root/NavBar'

const Content = styled.div`
	${tw`w-full max-w-screen-md px-5 mx-auto text-copyXl`}

	strong {
		${tw`font-futuraBold`}
	}
	p {
		${tw`pb-6`}
	}

	ul {
		${tw`pb-spacingXs-xs`}
		br {
			display: none;
		}
		li {
			position: relative;
			padding-left: 1.5rem;
			&::before {
				position: absolute;
				left: 0;
				width: 0.75rem;
				height: 0.75rem;
				background-size: contain;
				top: 10px;
				background-repeat: no-repeat;
				content: '';
				background-image: url(${listArrow});
			}
		}
	}
	a {
		text-decoration: underline;

		&:hover {
			${tw`text-primary`}
		}
	}
`
const BackWrap = tw.div`max-w-screen-lg mx-auto mt-spacingS-xs`
const Flex = tw.div`px-5 flex items-center gap-4`
const ShowMore = tw(
	Link
)`uppercase block relative mx-5 xl:mx-0 pt-2 mt-8 text-sm cursor-pointer text-primary tracking-1 md:tracking-2 before:absolute before:w-24 before:bottom-full before:left-0 before:h-px before:bg-light`

export default function PressTemplate({ data, path }) {
	const page = data.main
	const { locale } = page.locale
	const lang = locale.substr(0, 2)
	useGTM({ locale, path, pageType: page?.pageOptionsPageType?.pageType || 'presse' })

	const replaceInlineImage = (node) => {
		if (node.name === 'img') {
			node.src = node.attribs['data-src']
			if (node.attribs['data-src'] !== undefined) {
				return <img src={node.attribs['data-src']} loading='lazy' alt='Some alternative text' />
			}
			return node
		}
	}

	return (
		<>
			<NavBar translationSlug={page.translated[0]?.uri} />
			<SEO seoData={page.seo} article={true} />
			<WpAcfHeaderprimaryBlock
				press={true}
				data={{ modulesHeaderPrimary: { image: page.featuredImage?.node, headline: page.title, text: null } }}
				locale={locale}
				overline={strings[lang].singlePress}
			/>
			<Content>
				{page.blocks.map((module, index) => {
					if (module.__typename.includes('WpCore') && !!module.originalContent && typeof module.originalContent === 'string') {
						return <div key={`wpcore-${index}`}>{parse(module.originalContent, { replace: replaceInlineImage })}</div>
					}
					if (module.__typename.includes('WpAcfImageBlock')) {
						return <WpAcfImageBlock data={module} key={`${module.__typename}-${index}`} press={true} />
					}
					return null
				})}
			</Content>
			<BackWrap>
				<ShowMore to={lang === 'de' ? '/presse/' : '/en/presse/'}>
					<Flex>
						<Chevron state='left' /> {strings[lang].backToPress}
					</Flex>
				</ShowMore>
			</BackWrap>
			<Footer />
		</>
	)
}

export const query = graphql`
	query ($post: String) {
		main: wpPost(id: { eq: $post }) {
			featuredImage {
				node {
					localFile {
						childImageSharp {
							gatsbyImageData(width: 1600, formats: [AUTO, WEBP])
						}
					}
					altText
				}
			}
			blocksJSON
			blocks {
				__typename
				...AllBlocks
				... on WpCoreParagraphBlock {
					originalContent
				}
				... on WpCoreListBlock {
					originalContent
				}
				... on WpCoreFreeformBlock {
					originalContent
				}
				... on WpCoreHeadingBlock {
					originalContent
				}
				... on WpAcfImageBlock {
					attributes {
						anchor
					}
					attributesJSON
					modulesImage {
						image {
							localFile {
								childImageSharp {
									gatsbyImageData(width: 2000, quality: 85, formats: [AUTO, WEBP])
								}
							}
							altText
						}
					}
				}
			}
			locale {
				locale
			}
			translated {
				slug
				uri
			}
			id
			slug
			seo {
				metaDesc
				title
				opengraphImage {
					localFile {
						publicURL
					}
				}
				schema {
					pageType
				}
			}
			uri
			title
			status
			content
			excerpt
			pageOptionsPageType {
				pageType
			}
		}
	}
`
