import React from 'react'
import tw from 'twin.macro'
import EmfImage from '@elements/EmfImage'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Section = tw.section`relative px-offset-xs`
const ImageWrap = tw.div`relative sm:px-offset-xs lg:px-offset-lg max-w-screen-xl mx-auto`
const PressImageWrap = tw.div`relative max-w-screen-xl mx-auto py-12`
const RatioWrap = tw.div`relative w-full`

const WpAcfImageBlock = ({ data, press }) => {
	const { anchor } = data.attributes
	const module = data.modulesImage
	if (!module.image) {
		return null
	}
	if (press) {
		return (
			<PressImageWrap>
				<GatsbyImage image={getImage(module.image.localFile)} alt={module.image?.altText} className='rounded-emf' />
			</PressImageWrap>
		)
	}
	return (
		module.image && (
			<Section {...(anchor !== '' ? { id: anchor } : {})} className='emf-pb-spacing'>
				<ImageWrap>
					<RatioWrap className='z-0 aspect-w-16 aspect-h-9'>
						<EmfImage src={module.image.localFile} />
					</RatioWrap>
				</ImageWrap>
			</Section>
		)
	)
}
export default WpAcfImageBlock
